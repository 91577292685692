import homePageScreen7 from '@/assets/messageDemo/homePageScreen7.png';
import homePageScreen10 from '@/assets/messageDemo/homePageScreen10.png';
import homePageScreen1 from '@/assets/messageDemo/homePageScreen1.png';
import homePageScreen12 from '@/assets/messageDemo/homePageScreen12.png';
import homePageScreen3 from '@/assets/messageDemo/homePageScreen3.png';
import homePageScreen8 from '@/assets/messageDemo/homePageScreen8.png';
import homePageScreen4 from '@/assets/messageDemo/homePageScreen4.png';
import homePageScreen14 from '@/components/homePage/img/dashIns.png';
import homePageScreen15 from '@/components/homePage/img/dashWhatsapp.png';
import homePageScreen2 from '@/assets/messageDemo/homePageScreen2.png';
import homePageScreen13 from '@/assets/messageDemo/homePageScreen13.png';
import homePageScreen9 from '@/assets/messageDemo/homePageScreen9.png';
import homePageScreen11 from '@/assets/messageDemo/homePageScreen11.png';
import homePageScreen5 from '@/assets/messageDemo/homePageScreen5.png';
import homePageScreen6 from '@/assets/messageDemo/homePageScreen6.png';

export const jsonDataRuleList = {
  code: 200,
  message: 'OK',
  data: {
    app_block_rules: [
      {
        id: '652f42883c15fcb3eff2808f',
        apps: [
          {
            package_name: '50680912-5cd4-4cd8-9257-79934ed5ea40',
            logo: homePageScreen7,
          },
        ],
        cycle: 62,
        block_start_time: '8:00',
        block_end_time: '18:00',
        showSingleDele: false,
      },
      {
        id: '652f42883c15fcb3eff28090',
        apps: [
          {
            package_name: '8c9bff15-02cf-4a6f-8ea4-f48835bad055',
            logo: homePageScreen10,
          },
        ],
        cycle: 127,
        block_start_time: '22:00',
        block_end_time: '6:00',
        showSingleDele: false,
      },
    ],
    update_time: 1700124834,
  },
};

export const jsonDataAllUndisable = {
  code: 200,
  message: 'OK',
  data: {
    unblock_apps: [
      {
        package_name: '99e6a4af-7dcb-4cb4-9bca-b0b566262be5',
        name: 'Spotify',
        logo: homePageScreen1,
      },
      {
        package_name: 'd40d7c5c-2022-42ae-9941-3c5003b775ba',
        name: 'Duolingo',
        logo: homePageScreen12,
      },
      {
        package_name: 'abab805e-ca8e-424b-831e-476c9f258441',
        name: 'Netflix',
        logo: homePageScreen3,
      },
      {
        package_name: 'b1247f96-61a8-41df-94bc-0aa0f61efb77',
        name: 'Snapchat',
        logo: homePageScreen8,
      },
      {
        package_name: '220aaf07-78dd-41c3-92ac-37d8f76f56c5',
        name: 'X',
        logo: homePageScreen4,
      },
      {
        package_name: '480065ce-9a1f-4c94-b28a-3bc80000296c',
        name: 'WhatsApp',
        logo: homePageScreen14,
      },
      {
        package_name: '32ca90d4-93fd-46e5-b937-0d0737deeebe',
        name: 'Instagram',
        logo: homePageScreen15,
      },
      {
        package_name: 'cf6405f9-2715-400f-8740-7deeee86e2e8',
        name: 'YouTube',
        logo: homePageScreen2,
      },
      {
        package_name: '62b1c94d-35a6-4be0-a7cc-cff30b7f4129',
        name: 'Pinterest',
        logo: homePageScreen13,
      },
      {
        package_name: '71536dac-f68e-466d-9658-6d2b3394646b',
        name: 'Reddit',
        logo: homePageScreen9,
      },
      {
        package_name: 'b9522756-092f-4c69-a3a3-7cb2c591987b',
        name: 'Clash of Clans',
        logo: homePageScreen11,
      },
      {
        package_name: 'e03bfa96-273a-4625-ab65-111024b0af0a',
        name: 'Pokémon GO',
        logo: homePageScreen5,
      },
      {
        package_name: '3c3f840a-061f-4b88-bc37-3d4154417760',
        name: 'Facebook',
        logo: homePageScreen6,
      },
    ],
    update_time: 1697596040,
  },
};
